import Vue from 'vue'
import App from './App.vue'
import router from './router'
import htmlToPdf from '@/components/utils/htmlToPdf'
import watermark from "@/components/utils/waterMark"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import html2canvas  from 'html2canvas'
import axios from "./utils/axios"

// 使用Vue.use()方法就会调用工具方法中的install方法
Vue.use(htmlToPdf)
Vue.use(ElementUI)
Vue.use(html2canvas)
Vue.prototype.$watermark = watermark
Vue.config.productionTip = false
Vue.prototype.$http=axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
