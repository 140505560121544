import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/test1',
    name: 'test1',
    component: () => import('../views/test/test1.vue')
  },
  {
    path: '/test2',
    name: 'test2',
    component: () => import('../views/test/test2.vue')
  },
  {
    path: '/test3',
    name: 'test3',
    component: () => import('../views/test/test3.vue')
  },
  {
    path: '/test4',
    name: 'test4',
    component: () => import('../views/test/test4.vue')
  },
  {
    path: '/chainList',
    name: 'chainList',
    component: () => import('../views/ChainCertificate.vue')
  },
  {
    path: '/newchainList',//pc端路径
    name: 'newchainList',
    component: () => import('../views/NewChainCertificate.vue')
  },
  {
    path: '/ChainCollection',//pc端路径
    name: 'ChainCollection',
    component: () => import('../views/ChainCollection.vue')
  },
  
  // {
  //   path: '/appArrList',//移动端路径
  //   name: 'appArrList',
  //   component: () => import('../views/AppArrList.vue')
  // },
  {
    path: '/dialog',
    name: 'dialogList',
    component: () => import('../views/DialogList.vue')
  },
  //  {
  //   path: '/uniapp',//移动端css
  //   name: 'uniapp',
  //   component: () => import('../views/UniappArrList.vue')
  // },
  {
    path: '/test5',
    name: 'test5',
    component: () => import('../views/test/test5.vue')
  }
]
// history
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
