import axios from "axios"
axios.defaults.timeout = 30 * 1000
axios.defaults.baseURL =   'http://116.90.87.106:8080/managementApi'  //'https://hfcertificate.xinfushenghuo.cn/managementApi'  
  
 //'https://www.gjjteam.top/v1/' 

/* 路由守卫 只有登陆过后 才能访问Home页 否则不可以 */
/* 請求攔截器預處理  config----請求對象 對象身上有很多屬性 headers  */
// axios.interceptors.request.use(config=>{
//  config.headers.Authorization =  'Bearer '+window.sessionStorage.getItem("token")
//  return config
// })
export default axios